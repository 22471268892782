import trackerApi from "../api/tracker";

import { navigate } from "gatsby";

export const getAllPartners = async () => {
    const user = window.localStorage.getItem("gatsbyUser");
    const token = JSON.parse(user).token;

    try {
        const response = await trackerApi.get("/getPartner", {
            headers: { Authorization: `Bearer ${token}` },
            params: { token },
        });
        return response.data;
    } catch (error) {
        console.log("error getPartner :", error);
    }
};

export const getPartnerById = async (id) => {
    const user = window.localStorage.getItem("gatsbyUser");
    const token = JSON.parse(user).token;

    try {
        const partner = await trackerApi.get(`/getPartnerById/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
            params: { token, id },
        });
        return partner.data;
    } catch (error) {
        console.log("error can't get a partner : ", error);
    }
};

export const addPartner = async ({
    name,
    partnerType,
    url,
    contactName,
    phoneNumber,
    email,
    instagram,
    facebook,
    twitter,
    snapchat,
    streetNumber,
    streetName,
    city,
    description,
}) => {
    const user = window.localStorage.getItem("gatsbyUser");
    const token = JSON.parse(user).token;

    try {
        const partner = await trackerApi.post(
            "/addPartner/",
            {
                token,
                name,
                partnerType,
                contactName,
                url,
                email,
                instagram,
                facebook,
                twitter,
                snapchat,
                phoneNumber,
                streetNumber,
                streetName,
                city,
                description,
            },
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
        return partner.data;
    } catch (error) {
        console.log("error can't add an partner : ", error);
    }
};

export const updatePartnerById = async ({
    partnerId,
    name,
    partnerType,
    url,
    contactName,
    phoneNumber,
    email,
    instagram,
    facebook,
    twitter,
    snapchat,
    streetNumber,
    streetName,
    city,
    description,
}) => {
    const user = window.localStorage.getItem("gatsbyUser");
    const token = JSON.parse(user).token;

    try {
        const response = await trackerApi.post(
            "/updatePartner",
            {
                token,
                partnerId,
                name,
                partnerType,
                url,
                contactName,
                phoneNumber,
                email,
                instagram,
                facebook,
                twitter,
                snapchat,
                streetNumber,
                streetName,
                city,
                description,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (response.data.isAddressCorrect === "wrong") {
            return response.data
        }


        navigate("/app/allPartners");
    } catch (error) {
        console.log("ERROR can't update a partner");
    }
};

export const addPartnerLogo = async ({ imgEventsBase64, partnerId }) => {
    const user = window.localStorage.getItem("gatsbyUser");
    const token = JSON.parse(user).token;

    try {
        const addImageSelected = await trackerApi.post(
            "/logoPartner",
            {
                token,
                imgEventsBase64,
                partnerId,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log("addImageSelected :", addImageSelected);
    } catch (error) {
        console.log("ERROR can't add image for partner");
    }
};

export const addPartnerImg = async ({
    imgEventsBase64,
    partnerId,
    pictureChoice,
}) => {
    const user = window.localStorage.getItem("gatsbyUser");
    const token = JSON.parse(user).token;

    try {
        const addImageSelected = await trackerApi.post(
            "/imgPartner",
            {
                token,
                imgEventsBase64,
                partnerId,
                pictureChoice,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        console.log("addImageSelected :", addImageSelected);
    } catch (error) {
        console.log("ERROR can't add image : ", pictureChoice);
    }
};

export const deletePartner = async ({ partnerId }) => {
    const user = window.localStorage.getItem("gatsbyUser");
    const token = JSON.parse(user).token;

    try {
        await trackerApi.post(
            "/deletePartner/",
            {
                token,
                partnerId,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        navigate("/app/allPartners");
    } catch (error) {
        console.log("ERROR can't delete a partner");
    }
};

