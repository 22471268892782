import React, { useState, useContext } from "react";
import "../../assets/style/globalStyle.scss";
import {
    addPartner,
} from "../../services/partnerService";
// import ImagePicker from "../../components/imagePicker";
// import LogoPicker from "../../components/logoPicker"

import { navigate } from "gatsby";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";

import { Formik } from "formik";
import * as Yup from "yup";

const CreatePartnerSchema = Yup.object().shape({
    name: Yup.string().required("Veuillez entrer le nom du partenaire"),
    partnerType: Yup.string().required("Veuillez choisir la nature du partenaire"),
    contactName: Yup.string("Veuillez entrer le nom du contact de la société"),
    phoneNumber: Yup.string().matches(/^[0-9]+$/, "Caractères invalides"),
    email: Yup.string().email("Email invalide"),
    streetNumber: Yup.string().required("Veuillez entrer le numéro de rue"),
    streetName: Yup.string().required("Veuillez entrer le nom de rue"),
    city: Yup.string().required("Veuillez entrer le nom de la ville"),
});

const CreatePartnerPage = () => {
    const dispatch = useContext(GlobalDispatchContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [isAddressCorrect, setisAddressCorrect] = useState(true)

    // const GoToPartner = async (id) => {
    //     const playground = await getPartnerById(id);
    //     dispatch({
    //         type: "load_partner",
    //         payload: partner.partner,
    //     });
    //     navigate("/app/updatePlaygroundPage");
    // };

    return (
        <div className="box">
            <h3 className="title mt-3">AJOUTER UN PARTENAIRE</h3>
            <h4 className="subtitle">
                Formulaire d'ajout d'un partenaire pour un admin BNC
            </h4>
            <p>* Champs obligatoires</p>

            <br />
            <Formik
                initialValues={{
                    name: "",
                    partnerType: "",
                    contactName: "",
                    url: "",
                    phoneNumber: "",
                    email: "",
                    instagram: "",
                    facebook: "",
                    twitter: "",
                    snapchat: "",
                    streetNumber: "",
                    streetName: "",
                    city: "",
                    description: "",
                }}
                validationSchema={CreatePartnerSchema}
                onSubmit={async (values) => {
                    try {
                        const newPartner = await addPartner({
                            name: values.name,
                            partnerType: values.partnerType,
                            contactName: values.contactName,
                            url: values.url,
                            phoneNumber: values.phoneNumber,
                            email: values.email,
                            instagram: values.instagram,
                            facebook: values.facebook,
                            twitter: values.twitter,
                            snapchat: values.snapchat ,
                            streetNumber: values.streetNumber,
                            streetName: values.streetName,
                            city: values.city,
                            description: values.description,
                        });

                        if (newPartner.isAddressCorrect === 'wrong') {
                            return setisAddressCorrect(false)
                        }
                        
                        dispatch({
                            type: "get_partner",
                            payload: newPartner,
                        });
                        
                        navigate("/app/updatePartnerPage")
                    } catch (error) {
                        setErrorMessage(
                            "Erreur lors de l'ajout, une adresse introuvable ou un champ incomplet"
                        );
                    }

                }}
            >
                {({
                    setFieldValue,
                    handleChange,
                    handleSubmit,
                    values,
                    errors,
                    touched,
                }) => (
                    <>
                        <div className="columns is-centered">
                            <div className="column">
                                <div className="field">
                                    <label className="label">
                                        Nom du partenaire *
                                        <input
                                            className="input"
                                            type="text"
                                            placeholder="Nom du partenaire"
                                            value={values.name}
                                            onChange={(e) =>
                                                setFieldValue("name", e.target.value)
                                            }
                                        />
                                    </label>
                                </div>
                            </div>
                            {errors.name && touched.name && (
                                <p style={{ color: "#cc0000" }}>{errors.name}</p>
                            )}

                            <div className="column">
                                <label className="label">
                                    Nature du partenaire *
                                    <br />
                                    <div
                                        className="select"
                                        value={values.partnerType}
                                        onChange={(e) => setFieldValue("partnerType", e.target.value)}
                                    >
                                        <select>
                                            <option value="">Sélectionner la nature du partenaire</option>
                                            <option value="Club">Club</option>
                                            <option value="Marque">Marque</option>
                                            <option value="Shop">Shop</option>
                                            <option value="Complexe sportif">Complexe sportif</option>
                                            <option value="Mairie">Mairie</option>
                                            <option value="Association">Association</option>
                                        </select>
                                    </div>
                                </label>
                                {errors.partnerType && touched.partnerType && (
                                    <p style={{ color: "#cc0000" }}>{errors.partnerType}</p>
                                )}
                            </div>

                            <div className="column ">
                                <div className="field">
                                    <label className="label">
                                        URL
                                        <input
                                            className="input"
                                            type="url"
                                            placeholder="URL du partenaire"
                                            value={values.url}
                                            onChange={(e) => setFieldValue("url", e.target.value)}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="columns is-centered">
                            <div className="column ">
                                <div className="field">
                                    <label className="label">
                                        Nom/prénom du contact *
                                        <input
                                            className="input"
                                            type="text"
                                            placeholder="Nom/prénom contact du partenaire"
                                            value={values.contactName}
                                            onChange={(e) =>
                                                setFieldValue("contactName", e.target.value)
                                            }
                                        />
                                    </label>
                                </div>
                                {errors.contactName && touched.contactName && (
                                    <p style={{ color: "#cc0000" }}>{errors.contactName}</p>
                                )}
                            </div>

                            <div className="column ">
                                <div className="field">
                                    <label className="label">
                                        Numéro de téléphone
                                        <input
                                            className="input"
                                            type="tel"
                                            placeholder="Numéro de téléphone du partenaire"
                                            value={values.phoneNumber}
                                            onChange={(e) =>
                                                setFieldValue("phoneNumber", e.target.value)
                                            }
                                        />
                                    </label>
                                </div>
                                {errors.phoneNumber && touched.phoneNumber && (
                                    <p style={{ color: "#cc0000" }}>{errors.phoneNumber}</p>
                                )}
                            </div>
                            <div className="column ">
                                <div className="field">
                                    <label className="label">
                                        Email *
                                        <input
                                            className="input"
                                            type="email"
                                            placeholder="Email du partenaire"
                                            value={values.email}
                                            onChange={(e) => setFieldValue("email", e.target.value)}
                                        />
                                    </label>
                                </div>
                                {errors.email && touched.email && (
                                    <p style={{ color: "#cc0000" }}>{errors.email}</p>
                                )}
                            </div>
                        </div>
                        <hr />
                        <div className="columns is-centered">
                            <div className="column ">
                                <div className="field">
                                    <label className="label">
                                        Instagram
                                        <input
                                            className="input"
                                            type="text"
                                            placeholder="Compte Instagram du partenaire"
                                            value={values.instagram}
                                            onChange={(e) =>
                                                setFieldValue("instagram", e.target.value)
                                            }
                                        />
                                    </label>
                                </div>
                            </div>
                            <div className="column ">
                                <div className="field">
                                    <label className="label">
                                        Facebook
                                        <input
                                            className="input"
                                            type="tel"
                                            placeholder="Compte Facebook du partenaire"
                                            value={values.facebook}
                                            onChange={(e) =>
                                                setFieldValue("facebook", e.target.value)
                                            }
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="columns is-centered">
                            <div className="column ">
                                <div className="field">
                                    <label className="label">
                                        Twitter
                                        <input
                                            className="input"
                                            type="text"
                                            placeholder="Compte twitter du partenaire"
                                            value={values.twitter}
                                            onChange={(e) =>
                                                setFieldValue("twitter", e.target.value)
                                            }
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="column ">
                                <div className="field">
                                    <label className="label">
                                        Snapchat
                                        <input
                                            className="input"
                                            type="tel"
                                            placeholder="Compte Snapchat du partenaire"
                                            value={values.snapchat}
                                            onChange={(e) =>
                                                setFieldValue("snapchat", e.target.value)
                                            }
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <hr />

                        {!isAddressCorrect && (
                            <p style={{ color: "#cc0000", textAlign: "center", marginBottom: 10 }}>L'adresse est incorrect, modifiez-là</p>
                        )}
                        <div className="columns is-centered">
                            <div className="column is-one-quarter">
                                <label className="label">
                                    N° de Rue *
                                    <input
                                        className="input"
                                        type="numeric"
                                        placeholder="N° de rue"
                                        value={values.streetNumber}
                                        onChange={(e) =>
                                            setFieldValue("streetNumber", e.target.value)
                                        }
                                    />
                                </label>
                                {errors.streetNumber && touched.streetNumber && (
                                    <p style={{ color: "#cc0000" }}>{errors.streetNumber}</p>
                                )}
                            </div>
                            <div className="column">
                                <label className="label">
                                    Nom de la rue *
                                    <input
                                        className="input"
                                        type="text"
                                        placeholder="Nom de la rue"
                                        value={values.streetName}
                                        onChange={(e) =>
                                            setFieldValue("streetName", e.target.value)
                                        }
                                    />
                                </label>
                                {errors.streetName && touched.streetName && (
                                    <p style={{ color: "#cc0000" }}>{errors.streetName}</p>
                                )}
                            </div>

                            <div className="column is-one-quarter">
                                <label className="label">
                                    Ville *
                                    <input
                                        className="input"
                                        type="text"
                                        placeholder="Nom de la ville"
                                        value={values.city}
                                        onChange={(e) => setFieldValue("city", e.target.value)}
                                    />
                                </label>

                                {errors.city && touched.city && (
                                    <p style={{ color: "#cc0000" }}>{errors.city}</p>
                                )}
                            </div>
                        </div>
                        <hr />
                        <br />
                        <div className="field">
                            <label className="label">
                                Description
                                <textarea
                                    className="textarea"
                                    placeholder="Description"
                                    value={values.description}
                                    onChange={(e) => setFieldValue("description", e.target.value)}
                                ></textarea>
                            </label>
                        </div>
                        <br />
                        {errorMessage !== "" && (
                            <>
                                <p>{errorMessage}</p>
                                <br />
                            </>
                        )}

                        <div className="columns is-multiline">
                            <div className="column">
                                <button
                                    className="button is-success is-outlined"
                                    type="submit"
                                    onClick={async () => handleSubmit()}
                                >
                                    Ajouter le partenaire
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </Formik>
        </div>
    );
};

export default CreatePartnerPage;